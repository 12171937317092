import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../layouts/index"
import SocialShare from "../components/social-share"
import SEO from "../components/seo"
import config from "../config/site"
import * as stringUtils from "../utils/string"

const PostTemplate = props => {
  const { data, pageContext } = props
  const { next, prev } = pageContext
  const post = data.wordpressPost

  return (
    <Layout cssClass="single-post blog-style">
      <SEO title={stringUtils.sanitize(post.title)} description={post.yoast_meta.yoast_wpseo_metadesc} />
      <div className="main-content">
        <div className="post-container">
          <div className="post-meta">
            <div className="post-meta-inner">
              <div className="author-thumb">
                <img src={post.author_meta.avatar_url} alt="" />
              </div>
              <div className="author-meta">
                <div className="author-meta-related">
                  <span className="author-name">{post.author_meta.display_name}</span>
                  <span className="post-date">
                    {new Date(post.date).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>
                <div className="author-social-links">
                  <ul>
                    <li>
                      <a
                        className="twitter-link"
                        href={`https://twitter.com/${post.author_meta.twitter_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="twitter"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="linkedin-link"
                        href={post.author_meta.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="linkedin-in"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h1 className="post-title" dangerouslySetInnerHTML={{ __html: post.title }} />
          <ul className="post-categories">
            {post.post_categories.map(category => (
              <li
                className="post-category"
                key={category.color}
                style={{
                  color: `${category.text_color}`,
                  backgroundColor: `${category.color}`,
                  borderColor: `${category.color}`,
                }}
              >
                <Link
                  className="post-category-link"
                  to={`/blog/categories/${category.slug}`}
                  style={{ textDecoration: `none` }}
                >
                  {category.title}
                </Link>
              </li>
            ))}
          </ul>
          <div className="post-thumbnail">
            <img src={post.featured_media_url} alt="" />
            <SocialShare siteURL={config.siteURL} postSlug={post.slug} postTitle={post.title} />
          </div>
          <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
          <div className="pagination">
            {prev && (
              <div className="previous">
                <Link className="link" to={`/blog/${prev.slug}`}>
                  <span className="pagination-title">Précédent</span>
                  <span className="article-title">{stringUtils.sanitize(prev.title)}</span>
                </Link>
              </div>
            )}
            {next && (
              <div className="next">
                <Link className="link" to={`/blog/${next.slug}`}>
                  <span className="pagination-title">Suivant</span>
                  <span className="article-title">{stringUtils.sanitize(next.title)}</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default PostTemplate

export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media_url
      post_categories {
        color
        title
        slug
      }
      author_meta {
        display_name
        avatar_url
        twitter_url
        linkedin_url
      }
    }
  }
`
PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}
