import React from "react"
import PropTypes from "prop-types"
import * as stringUtils from "../utils/string"

const SocialShare = ({ siteURL, postTitle, postSlug }) => {
  const parsedPostSlug = stringUtils.sanitize(postSlug)
  const parsedPostTitle = stringUtils.sanitize(postTitle)
  const openWindow = settings => e => {
    window.open(e.href, settings.title, `width=${settings.sizes.w},height=${settings.sizes.h}`)
    return true
  }
  return (
    <div className="social-share">
      <ul className="social-share-links">
        <li className="facebook-share">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${`${siteURL}/blog/${parsedPostSlug}`}`}
            onClick={openWindow({ title: "facebook-share", sizes: { w: 580, h: 296 } })}
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </li>
        <li className="twitter-share">
          <a
            href={`http://twitter.com/share?text=${parsedPostTitle}&url=${`${siteURL}/blog/${parsedPostSlug}`}`}
            onClick={openWindow({ title: "twitter-share", sizes: { w: 550, h: 235 } })}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
        <li className="linkedin-share">
          <a
            href={`https://www.linkedin.com/shareArticle?url=${`${siteURL}/${parsedPostSlug}`}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Linkedin
          </a>
        </li>
      </ul>
    </div>
  )
}
export default SocialShare

SocialShare.propTypes = {
  siteURL: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postSlug: PropTypes.string.isRequired,
}
